import React from 'react';
import './Expert.scss';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import { BsFillCheckCircleFill } from "react-icons/bs";
import expertImg from '../../assets/expert.png';

const Expert = () => {
    return (
        <section className='expert-section' data-aos="fade-up" data-aos-duration="2000">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6">
                        <div className="expert-text">
                            <SectionTitle
                                subTitle="EXPERTS IN DENTAL & COSMETOLOGY"
                                title="Top Care in Dentistry and Cosmetology"
                                description="Discover exceptional dental and cosmetology services provided by our team of experts, dedicated to enhancing your health and beauty."

                            />

                            <ul>
                                <li>
                                    <BsFillCheckCircleFill />
                                    Top-quality dental and cosmetology team
                                </li>
                                <li>
                                    <BsFillCheckCircleFill />
                                    State-of-the-art dental and beauty services
                                </li>
                                <li>
                                    <BsFillCheckCircleFill />
                                    Discounts on all treatments and services
                                </li>
                            </ul>

                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="expert-img">
                            <img src={expertImg} alt="expert" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Expert;