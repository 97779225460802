// import iconOne from '../../assets/service/1.png';
// import iconTwo from '../../assets/service/2.png';
// import iconThree from '../../assets/service/3.png';
// import iconFour from '../../assets/service/4.png';
// import iconFive from '../../assets/service/5.png';
// import iconSix from '../../assets/service/6.png';
// import iconSeven from '../../assets/service/7.png';
import img1 from '../../assets/service/Dental/scaling.jpg';
import img2 from '../../assets/service/Dental/filling.jpg';
import img3 from '../../assets/service/Dental/root-canal.jpg';
import img4 from '../../assets/service/Dental/crowns.jpg';
import img5 from '../../assets/service/Dental/implant.jpg';
import img6 from '../../assets/service/Dental/Orthodontic braces.jpg';
import img7 from '../../assets/service/Dental/smile.jpg';
import img8 from '../../assets/service/Dental/extraction.jpg';
const ServicesDatas = [
    {
        'icon': img1,
        'title': 'Scaling and Polishing',
        'description': 'Enhance your smile with our thorough dental cleaning and polishing for a brighter, healthier look.',
    },
    {
        'icon': img2,
        'title': 'Tooth Filling',
        'description': 'Repair cavities and restore tooth function with our quality fillings.',
    },
    {
        'icon': img3,
        'title': 'Root Canal Treatment',
        'description': ' Save your natural tooth and relieve pain with our precise root canal procedures.',
    },
    {
        'icon': img4,
        'title': 'Bridges and Crowns',
        'description': 'Restore function and aesthetics with durable bridges and crowns, crafted for a perfect fit.',
    },
    {
        'icon': img5,
        'title': 'Implant',
        'description': 'Replace missing teeth with durable and natural-looking implants.',
    },
    {
        'icon': img6,
        'title': 'Orthodontic Braces',
        'description': 'Straighten your teeth and achieve a beautiful smile with effective orthodontic braces.',
    },
    {
        'icon': img7,
        'title': 'Smile Makeover',
        'description': 'Transform your smile with a comprehensive makeover tailored to enhance your appearance.',
    },
    {
        'icon': img8,
        'title': 'Extraction',
        'description': 'extraction is the removal of a tooth from its socket in the bone, often performed due to decay, damage, or crowding',
    },
   
]

export default ServicesDatas;