import React from 'react';
import logo from '../../assets/logo.png';
import './Footer.scss';
import { FaFacebookF, FaInstagram, FaTwitter} from "react-icons/fa";
import { Link } from 'react-router-dom';
import call from '../../assets/footer/calling.png';
import time from '../../assets/footer/time.png';
import location from '../../assets/footer/location.png';

const Footer = () => {

    const footerMenu = [
        {
            'name' : 'About Us',
            'link' : '/'
        },
        {
            'name' : 'Dental Services',
            'link' : '/'
        },
        {
            'name' : 'Dentist',
            'link' : '/'
        },
        // {
        //     'name' : 'Blogs',
        //     'link' : '/'
        // },
        // {
        //     'name' : 'FAQs',
        //     'link' : '/'
        // }
    ];

    const footerContacts = [
        {
            'title': 'Phone Number',
            'info': '+91 882-565-1269',
            'icon': call
        },
        {
            'title': 'Morning Open Hour',
            'info': '09:30 AM - 02:00 PM',
            'icon': time
        },
        {
            'title': 'Evening Open Hour',
            'info': '05:00 PM - 08:30 PM',
            'icon': time
        },
        {
            'title': 'Sunday',
            'info': 'Appointment only',
            'icon': time
        },
        {
            'title': 'Clinic Address',
            'info': 'No 465-467, Ayyan Complex, Madurai Main Road, Near Periyar Statue, Thirumangalam - 625706',
            'icon': location
        }
    ]

    return (
        <footer className='pt-100 pb-70'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-5">
                        <div className="footer-logo">
                            <img src={logo} alt="logo" />
                        </div>
                        <p>We use the latest and enhanced technology in the dental field for diagnoses and are completely reliable. We being the best dentist near Thirumangalam will make sure that your treatment is planned and scheduled at your convenience.	</p>

                        <div className="social-logo">
                            <p>Follow us on</p>
                            <ul>
                                <li><a href="/"><FaFacebookF/></a></li>
                                <li><a href="/"><FaTwitter/></a></li>
                                <li><a href="/"><FaInstagram/></a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-2">
                        <div className="footer-link">
                            <p>Quick Links</p>
                            <ul>
                                {
                                    footerMenu.map(singleMenu => <li><Link to="/">{singleMenu.name}</Link></li>)
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-5">
                        <div className="footer-contact">
                            <p>Contact & Information</p>

                            {
                                footerContacts.map(footerContact => {
                                    return  <div className="contact-list">
                                                <div className="contact-icon">
                                                    <img src={footerContact.icon} alt="call" />
                                                </div>
                                                <div className="contact-text">
                                                    <p>{footerContact.title}</p>
                                                    <h5>{footerContact.info}</h5>
                                                </div>
                                            </div>
                                })
                            }
                        </div>
                    </div>
                </div>

                <div className="copyright-area">
                    <div className='copy-text'>
                        <p>&copy; Dentalist. All Right Reserved</p>
                    </div>  
                    <div className='copy-links'>
                        <ul>
                            <li><Link to='/'>Terms of Use</Link></li>
                            <li><Link to='/'>Privacy Policy</Link></li>
                            <br></br>
                            <br></br>
                            <li><a href='https://www.linkedin.com/in/srinivasanr8' target='_blank' rel='noopener noreferrer'>Crafted with heart by Srinivasan</a></li>
                        </ul>
                    </div>                          
                </div>
            </div>
        </footer>
    );
};

export default Footer;